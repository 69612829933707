import React from "react";
import Portal from "./components/portal";
import Login from "./components/login";
import Layout from "./components/layout";
import { Routes, Route } from "react-router-dom";
import PasswordReset from "./components/passwordreset";
import FirstConnexion from "./components/firstconnexion";
import { GeneralProvider } from "./contexts/GeneralContext";
import Registration from "./components/registration";
import ChangePassword from "./components/changepassword";
import Oidc from "./components/oidc";

function App() {
  return (
    <GeneralProvider>
      <Routes>
        <Route path="/" element={<Layout />}>
          <Route index element={<Login />} />
          <Route path="portal" element={<Portal />} />
          <Route path="oidc" element={<Oidc />} />
          <Route path="register/:thematic" element={<Registration />} />
          <Route path="reset" element={<PasswordReset />} />
          <Route path="reset/:email" element={<PasswordReset />} />
          <Route path="firstconnexion/:id/:email/:token" element={<FirstConnexion />} />
          <Route path="payment/:id/:email/:secret/:token" element={<FirstConnexion />} />
          <Route path="resetPassword/:id/:email/:token" element={<ChangePassword />} />
          <Route path="*" element={<Login />} />
        </Route>
      </Routes>
    </GeneralProvider>
  );
}

export default App;