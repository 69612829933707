import React, { createContext, useRef, useState } from "react";
import { useEffect } from "react";

const GeneralContext = createContext(undefined);
const GeneralDispatchContext = createContext(undefined);

// A "provider" is used to encapsulate only the
// components that needs the state in this context
function GeneralProvider({ children }) {
  const detailsRef = useRef(null);

  const setShowSpinner = (showSpinner) => {
    generalDetails.showSpinner = showSpinner;
    setGeneralDetails({
        ...generalDetails
    });
  }

  const setAlerteShow = (showAlert) => {
    generalDetails.alert.show = showAlert;
    setGeneralDetails({
      ...generalDetails,
    });
  }

  const setAlert = (alert) => {
    generalDetails.alert = alert
    setGeneralDetails({
      ...generalDetails
    });
  }

  const [generalDetails, setGeneralDetails] = useState({
    showMenu: true,
    showFooter: true,
    showSpinner: false,
    setShowSpinner: setShowSpinner,
    setAlerteShow: setAlerteShow,
    setAlert: setAlert,
    maintenance: false,
    devMode : false,
    alert: {
      show: false,
      message: "",
      variant: "danger",
      header: "",
      dismissible: true,
      timout: 5000,
    }
  });

  useEffect(() => {
    if(generalDetails.alert.show && generalDetails.alert.dismissible) {
      var tm = setTimeout(() => {
        detailsRef.current.alert.show = false;
        setGeneralDetails({
          ...detailsRef.current
        });
      }, detailsRef.current.alert.timout);
      return () => clearTimeout(tm);
    }
  }, [generalDetails.alert.show, generalDetails.alert.dismissible]);

  useEffect(() => {
    detailsRef.current = generalDetails;
  }, [generalDetails]);

  return (
    <GeneralContext.Provider value={generalDetails}>
      <GeneralDispatchContext.Provider value={setGeneralDetails}>
        {children}
      </GeneralDispatchContext.Provider>
    </GeneralContext.Provider>
  );
}

export { GeneralProvider, GeneralContext, GeneralDispatchContext };