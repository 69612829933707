import { useContext, useEffect, useState } from "react";
import { useNavigate, useParams } from "react-router-dom";
import { Container, Card } from "react-bootstrap";
import styles from '../../styles/Firstconnexion.module.scss';
import SetupPassword from "../setuppassword";
import axios from "axios";
import { GeneralContext } from "../../contexts/GeneralContext";

const FirstConnexion = () =>{
    /*
        Si Secret present = Paiement
        Si Token présent = connexion ancien patient Emno
    */
    let { id, email, secret, token } = useParams();
    const navigate = useNavigate();
    const generalDetails = useContext(GeneralContext);

    const [userRole, setUserRole] = useState();

    useEffect(() => {
        if (id && email && token) {
            axios.post(process.env.REACT_APP_NUVEE_BASE_URL + '/users-permissions/checkUser', {id, email, token})
            .then(response => {
                    const { redirectTo, role } = response.data;
                    if (redirectTo) {
                        window.location.href = redirectTo;
                    } else {
                        setUserRole(role);
                    }
                })
                .catch((error) => {
                    console.error(error);
                    generalDetails.setAlert({
                        show: true,
                        message: "Il y a un problème de communication avec notre serveur. Veuillez réessayer plus tard",
                        header: "Erreur serveur",
                        dismissible: true,
                        variant: "danger",
                        timout: 5000,
                    });
                });
        }
    // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [id, email, token]);

    const handlePasswordChanged = (password) => {
        navigate('/login', { state: { email: email, password: password, submit: true, redirectAfterLoginFirstConnexion: true, secret: secret, id: id } });
    }

    return (
        <Container>
            <Card bsPrefix=" " className={`mt-5 ${styles.card}`}>
                <Card.Header className={`${styles.cardHeader}`} as="h1">
                    Inscription à Nuvee
                </Card.Header>
                <h1 className={styles.etape}>
                    &Eacute;tape 1 : Définition du mot de passe
                </h1>
                <Card.Body className={`${styles.cardBody}`}>
                    <SetupPassword id={id} email={email} token={token} afterPasswordChanged={handlePasswordChanged} userRole={userRole} />
                </Card.Body>
            </Card>
        </Container>
    );
}

export default FirstConnexion;