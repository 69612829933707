const firebaseConfigDev = {
    apiKey: "AIzaSyCicqygmV9hVOS4UMGEiOP-EfTpJ_zSRlU",
    authDomain: "nuvee-75da0.firebaseapp.com",
    projectId: "nuvee-75da0",
    storageBucket: "nuvee-75da0.appspot.com",
    messagingSenderId: "891165656453",
    appId: "1:891165656453:web:ff6a3d35852dde6851f0ec",
    measurementId: "G-9C9JMRT5X7"
};

const firebaseConfigProd = {
    apiKey: "AIzaSyAOEDZkUFrjCntWCl0loiiN_tnNBFNDj8E",
    authDomain: "nuvee-prod.firebaseapp.com",
    projectId: "nuvee-prod",
    storageBucket: "nuvee-prod.appspot.com",
    messagingSenderId: "851528323304",
    appId: "1:851528323304:web:c7637cab6702304f35795d",
    measurementId: "G-P4W6WD2S2L"
};

module.exports = {
    firebaseConfigDev,
    firebaseConfigProd
} 