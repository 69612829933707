import { Container, Form, Button, Card } from "react-bootstrap";
import styles from '../../styles/PasswordReset.module.scss';
import React, { useContext, useState } from "react";
import { useNavigate, useParams } from "react-router-dom";
import axios from 'axios';
import { GeneralContext } from "../../contexts/GeneralContext";

const PasswordReset = () =>{
    const { email : email_param } = useParams();
    const navigate = useNavigate();
    const generalDetails = useContext(GeneralContext);
    const [email, setEmail] = useState(email_param);
    const onEmailChange = (event) => setEmail(event.target.value);

    const onSubmit = async (event) => {
        event.preventDefault();
        axios
            .post(process.env.REACT_APP_NUVEE_BASE_URL + '/auth/forgot-password', {
                email
            })
            .then(() => {
                generalDetails.setAlert({
                    show: true,
                    message: "Si l'adresse mail renseignée est rattachée à un compte Nuvee, un email pour effectuer vos inscription ou réinitialiser votre mot de passe vous a été envoyé",
                    header: "",
                    dismissible: true,
                    variant: "success",
                    timout: 5000
                });
            })
            .catch(error => {
                console.error(error);
            });
    };

    return (
        <Container>
            <Card bsPrefix=" " className={`mt-5 ${styles.card}`}>
                <Card.Header className={`${styles.cardHeader}`} as="h1">Recevoir mon lien de connexion</Card.Header>
                <Card.Body className={`mt-2 ${styles.cardBody}`}>
                    <Form className={`w-50 ${styles.loginForm}`} onSubmit={onSubmit}>
                        <Form.Group controlId="formBasicEmail">
                            <Form.Label>Email</Form.Label>
                            <Form.Control
                                type="email"
                                onChange={onEmailChange}
                                value={email}
                            />
                        </Form.Group>
                        <Button variant="primary" className={`mt-3 ${styles.sendButton}`} type="submit" disabled={email === ""}>
                            Valider
                        </Button>
                    </Form>
                    <Card.Link onClick={(_) => navigate('/', { state: { email: email}})} className="mt-3">Retour sur la page de Connexion</Card.Link>
                </Card.Body>
            </Card>
        </Container>
    );
}

export default PasswordReset;