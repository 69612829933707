function validatePassword(password ) {
    let res = false;
    var passwordValidator = require('password-validator');
    var schema = new passwordValidator();
    schema
    .is().min(7)                                    // Minimum length 7
    .is().max(20)                                   // Maximum length 20
    .has().uppercase()                              // Must have uppercase letters
    .has().lowercase()                              // Must have lowercase letters
    .has().digits(1)                                // Must have at least 1 digits
    .has().not().spaces();                          // Should not have spaces
    
    // Validate against a password string
    res = schema.validate(password);
    return res;
}
const globalKeys = {
    patient: "patient",
    patient_expert: "patient_expert",
    patient_prevention: "patient_prevention",
    entourage : "entourage",
    pro_standard : "pro_standard",
    pro_payeur : "pro_patient_payeur",
    pro_moderateur : "pro_moderateur",
    pro_entreprise : "pro_entreprise",
    pro_equipe : "pro_equipe",
    pro_etablissement : "pro_etablissement",
    administrateur: 'administrateur',
    dieteticienne : 'dieteticienne',
    phenotypage: 'pro_phenotypage'
}

const globalRegex = {
    stringRegex: /[a-zA-Zàâäéèêëìîïòôöùûü\- ]/g,
    stringWithNumbersRegex: /[0-9a-zA-Zàâäéèêëìîïòôöùûü\- ]/g,
    cityRegex: /[a-zA-Zàâäéèêëìîïòôöùûü\- ]/g,
    zipcodeRegex: /\b\d{5}\b/g,
    emailRegex: /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/,
    phoneRegex: /^((\+[0-9]{2})|0)[0-9]{9,11}$/,
    usernameRegex: /^[A-Za-z][A-Za-z0-9_]{5,29}$/,
    addresseRegex: /[0-9a-zA-Zàâäéèêëìîïòôöùûü\-, ]/g
}

export {
    validatePassword,
    globalKeys,
    globalRegex
}