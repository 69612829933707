import { useContext, useEffect } from "react";
import { useSearchParams } from "react-router-dom";
import { GeneralContext } from "../../contexts/GeneralContext";
import { Card, Container } from "react-bootstrap";
import axios from "axios";
import styles from '../../styles/Login.module.scss';

function Oidc() {
    const [queryParameters] = useSearchParams();
    const provider = queryParameters.get('provider');
    const thematicName = queryParameters.get('thematicName');
    const code = queryParameters.get('code');
    const generalDetails = useContext(GeneralContext);

    useEffect(() => {
        if(typeof provider == "string" && provider !== "" && thematicName && !code) {
            generalDetails.setShowSpinner(true);
            axios.post(process.env.REACT_APP_NUVEE_BASE_URL + '/users-permissions/openid', {requested_provider: provider, thematicName})
                .then(res => {
                    document.location.href = res.data.request;
                }).catch((error) => {
                    if(error.response.data && error.response.data.error?.message) {
                        generalDetails.setAlert({
                            show: true,
                            message: error.response.data.error.message,
                            header: "Erreur lors de la connexion SSO",
                            dismissible: true,
                            variant: "danger",
                            timout: 5000,
                        });
                    } else {
                        generalDetails.setAlert({
                            show: true,
                            message: "Une erreur est survenu. Veuillez contacter un administrateur.",
                            header: "Erreur interne",
                            dismissible: true,
                            variant: "danger",
                            timout: 5000,
                        });
                    }
                    console.error(error);
                })
                .finally(() => {
                    generalDetails.setShowSpinner(false);
                });
        } else if(provider && thematicName && code && typeof code == "string" && code !== "") {
            generalDetails.setShowSpinner(true);
            axios.post(process.env.REACT_APP_NUVEE_BASE_URL + '/users-permissions/openid/token', {
                requested_provider: provider,
                thematicName,
                code
            }).then(access => {
                axios.post(process.env.REACT_APP_NUVEE_BASE_URL + '/users-permissions/openid/loginIn', {
                    requested_provider: provider,
                    thematicName,
                    token: access.data.access_token
                }, { withCredentials: true }).then(authData => {
                    const token = authData.data.strapiToken;
                    const url = authData.data.url;
                    window.location.href = (url.indexOf('http') === -1 ? `https://${url}` : url ) + `?token=${token}`;
                });
            });
        }
    // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [provider, code, thematicName]);

    return (
        <Container>
            <Card bsPrefix=" " className={`mt-5 ${styles.card}`}>
                <Card.Header className={`${styles.cardHeader}`} as="h1">Connexion {provider && `depuis ${provider}`}</Card.Header>
            </Card>
        </Container>
    )
}
export default Oidc;