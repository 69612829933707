import React, { useContext, useEffect, useState } from "react";
import { Container, Form, Button, Card, InputGroup } from "react-bootstrap";
import { RecaptchaVerifier, getAuth, signInWithPhoneNumber, signOut} from "firebase/auth";
import { initializeApp } from "firebase/app";
import { getAnalytics } from "firebase/analytics";
import { Link, useLocation } from "react-router-dom";
import { faEye, faEyeSlash } from "@fortawesome/free-solid-svg-icons";
import styles from '../../styles/Login.module.scss';
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { GeneralContext } from "../../contexts/GeneralContext";
import axios from 'axios';
import OtpCode from "./otpCode";
import { firebaseConfigDev, firebaseConfigProd } from '../../firebase-config';

function Login() {
    const location = useLocation();
    const [oidcMode, setOidcMode] = useState(false);
    const [email, setEmail] = useState("");
    const [password, setPassword] = useState("");
    const [securityCode, setSecurityCode] = useState(["", "", "", "", "", ""]);
    const [otpCodeValid, setOtpCodeValide] = useState(false);
    const [isOtpReady, setIsOtpReady] = useState(false); // Disable temp the otp while the recaptcha is not ready
    const [loginInMode, setLoginInMode] = useState("");
    const [userPhone, setUserPhone] = useState(null);
    const [availableLoginInMode, setAvailableLoginInMode] = useState([]);
    const [showPass, setShowPass] = useState(false);
    const [doSubmit, setDoSubmit] = useState(false);
    const [lock, setLock] = useState(false);
    const [codeError, setCodeError]= useState(false);
    const [submitLock, setSubmitLock] = useState(false);
    const [firebaseApp, setFirebaseApp] = useState(null);
    const [firebaseAuth, setFirebaseAuth] = useState(null);
    const [recaptcha, setRecaptcha] = useState(null);
    const generalDetails = useContext(GeneralContext);

    useEffect(() => {
        if(location.state) {
            if(location.state.email) {
                setEmail(location.state.email);
            }
            if(location.state.password) {
                setPassword(location.state.password);
            }
            if(location.state.submit) {
                setDoSubmit(true);
            }
            if(location.state.oidcMode) {
                setOidcMode(location.state.oidcMode);
            }
        }
    }, [location.state]);

    useEffect(() => {
        if(doSubmit) {
            onSubmit();
            setDoSubmit(false);
        }
    // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [doSubmit]);
    // Your web app's Firebase configuration
    const firebaseConfig = process.env.REACT_APP_ENVIRONNEMENT === "PROD" ? firebaseConfigProd : firebaseConfigDev;

    const standardAlert = () => {
        generalDetails.setAlert({
            show: true,
            message: "Une erreur est survenu. Veuillez contacter un administrateur.",
            header: "Erreur interne",
            dismissible: true,
            variant: "danger",
            timout: 5000,
        });
    }

    const safeDisplayPhone = () => {
        if(userPhone) {
            return userPhone.slice(0, 4) + 'XXXXXX' + userPhone.slice(-2, userPhone.length);
        }
    }

    const redirectAfterLogin = (url, token, secret = null) => {
        if(location.state && location.state.redirectAfterLoginFirstConnexion) {
            if (secret && secret.length > 0) {
              window.location.href = `${url}/payment?id=${location.state.id}&secret=${secret}&token=${token}`;
            } else {
              window.location.href = `${url}/firstconnexion?token=${token}&id=${location.state.id}`;
            }
        } else {
            let tokenUrl = ``;
            if (url.indexOf("token=") === -1) tokenUrl = `?token=${token}`;
            if (url.indexOf("secret") === -1 && secret) tokenUrl += `&secret=${secret}`;
            window.location.href = (url.indexOf('http') === -1 ? `https://${url}` : url ) + `${tokenUrl}`;
        }
    }

    const onSubmit = async (event) => {
        event && event.preventDefault();
        if(email && email.trim() && password && password.trim() && !otpCodeValid) {
            generalDetails.setShowSpinner(true);
            axios
                .post(process.env.REACT_APP_NUVEE_BASE_URL + '/users-permissions/login', {
                    identifier: email,
                    password: password,
                    firstconnexion: location.state?.redirectAfterLoginFirstConnexion
                }, { withCredentials: true })
                .then(response => {
                    if(response && response.data?.IsValid && response.data?.modes) {
                        setLock(true);
                        setSubmitLock(true);
                        setAvailableLoginInMode(response.data.modes);
                        setUserPhone(response.data.phone);
                        if(response.data.modes.length === 1) {
                            setLoginInMode(response.data.modes[0]);
                        } else if (location.state?.redirectAfterLoginFirstConnexion && response.data.modes.includes('sms')) {
                            setLoginInMode('sms');
                        }
                    } else if (response.data?.IsValid && response.data?.token) {
                        const { url, token } = response.data;
                        redirectAfterLogin(url, token);
                    } else if(!response.data.IsValid && response.data.message) {
                        generalDetails.setAlert({
                            show: true,
                            message: response.data.message,
                            header: response.data.header,
                            dismissible: true,
                            variant: "warning",
                            timout: 5000,
                        });
                    } else {
                        standardAlert();
                    }
                }).catch(error => {
                    standardAlert();
                    console.error('An error occurred:', error.response);
                }).finally(() => generalDetails.setShowSpinner(false));
        } else if (otpCodeValid) {
            generalDetails.setShowSpinner(true);
            let data = {
                identifier: email
            }
            if(loginInMode === 'sms') {
                const confirmFunction = window.confirmationResult;
                try {
                    if(confirmFunction) {
                        const googleUser = await confirmFunction.confirm(securityCode.join(''));
                        data.googleTokenId = await googleUser.user.getIdToken()
                    } else {
                        // requête d'envoie de sms pas terminé
                        return;
                    }
                } catch(error) {
                    console.error(error);
                    if(error.code === 'auth/invalid-verification-code') {
                        generalDetails.setAlert({
                            show: true,
                            message: "Le code rentré semble incorrect.",
                            header: "Code erroné",
                            dismissible: true,
                            variant: "warning",
                            timout: 5000,
                        });
                        setCodeError(true);
                    } else if (error.code === 'auth/code-expired') {
                        getOTPCode();
                        generalDetails.setAlert({
                            show: true,
                            message: "Le code rentré semble expiré. Un nouveau code viens de vous être envoyé.",
                            header: "Code expiré",
                            dismissible: true,
                            variant: "warning",
                            timout: 7000,
                        });
                    } else {
                        generalDetails.setAlert({
                            show: true,
                            message: "Il semble que la vérification de votre code ne soit pas possible. Essayer une vérification par mail.",
                            header: "Erreur inconnu",
                            dismissible: true,
                            variant: "error",
                            timout: 5000,
                        });
                    }
                    setSecurityCode(["", "", "", "", "", ""]);
                    setOtpCodeValide(false);
                    generalDetails.setShowSpinner(false);
                    return;
                }
            } else {
                data.code = securityCode.join('')
            }
            axios
                .post(process.env.REACT_APP_NUVEE_BASE_URL + '/users-permissions/loginin', data, { withCredentials: true })
                .then(response => {
                    if(response && response.data.IsValid) {
                        const { url, token, secret } = response.data;
                        redirectAfterLogin(url, token, secret);
                    } else {
                        generalDetails.setAlert({
                            show: true,
                            message: "Le code rentré semble incorrect ou à expiré. Veuillez réessayer.",
                            header: "Code erroné",
                            dismissible: true,
                            variant: "warning",
                            timout: 5000,
                        });
                        generalDetails.setShowSpinner(false);
                        setCodeError(true);
                    }
                })
                .catch(error => {
                    standardAlert();
                    console.error('An error occurred:', error.response);
                    generalDetails.setShowSpinner(false)
                });
        }
    }

    const getOTPCode = (renewCode = false) => {
        if(loginInMode === 'sms') {
            setIsOtpReady(false);
            signInWithPhoneNumber(firebaseAuth, userPhone, recaptcha)
                .then((confirmationResult) => {
                    window.confirmationResult = confirmationResult;
                    if(renewCode) {
                        renewCodeHandler();
                    }
                }).catch((error) => {
                    // TODO:
                    // Reminder config environnement prod / preprod
                    // OIDC à finir quand environnement ok
                    // redirection Stripe penser à le noté / notifié / changement addresse du webhook prod
                    // Mail envoyées patient payeur + mot de passe reset
                    console.warn("Error; SMS not sent");
                    console.error(error);
                    if(error) {
                        recaptcha.clear();
                    }
                    generalDetails.setAlert({
                        show: true,
                        message: "Il semble y avoir un problème avec notre service de SMS.\n Code erreur: " + error.code,
                        header: "SMS non envoyé",
                        dismissible: true,
                        variant: "danger",
                        timout: 5000,
                    });
                }).finally(() => setIsOtpReady(true));
        } else {
            setIsOtpReady(true);
            axios
                .post(process.env.REACT_APP_NUVEE_BASE_URL + '/users-permissions/otplogin', {
                    mode: loginInMode,
                    identifier: email,
                    password: password
                })
                .then(_ => {
                    if(renewCode) {
                        renewCodeHandler();
                    }
                })
                .catch(error => {
                    if(error.status === 500) {
                        generalDetails.setAlert({
                            show: true,
                            message: "Notre serveur rencontre actuellement des difficultées, veuillez réessayer plus tard.",
                            header: "Erreur interne",
                            dismissible: true,
                            variant: "danger",
                            timout: 5000,
                        });
                    } else {
                        standardAlert();
                    }
                    console.error('An error occurred:', error.response);
                });
        }
        setSecurityCode(["", "", "", "", "", ""]);
        setOtpCodeValide(false);
    }

    useEffect(() => {
        const fc = initializeApp(firebaseConfig, 'NUVEE')
        setFirebaseApp(fc);
        getAnalytics(fc);
        if (loginInMode === 'sms' || loginInMode === 'mail') {
            getOTPCode();
        }
    // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [loginInMode]);

    useEffect(() => {
        if(firebaseApp && availableLoginInMode.length) {
            const auth = getAuth(firebaseApp);
            auth.useDeviceLanguage();
            setFirebaseAuth(auth);
            if(auth.currentUser) {
                signOut(auth).then(console.info).catch(console.error);
            }
            try {
                setRecaptcha(new RecaptchaVerifier(auth, 'sign-in-button', {
                    'size': 'invisible'
                }));
            } catch(error) {
                console.error(error);
            }
        }
    }, [firebaseApp, availableLoginInMode]);

    useEffect(() => {
        setSubmitLock((!(securityCode && otpCodeValid) && lock));
    // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [securityCode, otpCodeValid]);

    const getNewCode = () => {
        setOtpCodeValide(false);
        getOTPCode(true);
    }

    const renewCodeHandler = () => {
        generalDetails.setAlert({
            show: true,
            message: "Un nouveau code de vérification vous à été envoyé.",
            header: "Nouveau code envoyé",
            dismissible: true,
            variant: "info",
            timout: 5000,
        });
        setCodeError(false);
    }

    return (
        <Container>
            <Card bsPrefix=" " className={`mt-5 ${styles.card}`}>
                <Card.Header className={`${styles.cardHeader}`} as="h1">Connexion</Card.Header>
                <Card.Body className={`mt-2 ${styles.cardBody}`}>
                    {/* <div id="maintenance" className={`w-50 ${styles.maintenance} ${styles.loginForm}`}>
                        <p className="mt-3">Une mise à jour de Nuvee est actuellement en cours entrainant des perturbations dans son fonctionnement.</p>
                        <p className="mt-3">Merci pour votre compréhension.</p>
                        <Button variant="primary" className={`${styles.sendButton}`} onClick={() => document.querySelector('#maintenance').style.display = 'none'}>OK</Button>
                    </div> */}
                    <Form className={`w-50 ${styles.loginForm}`} onSubmit={onSubmit}>
                        {
                            !oidcMode &&
                            <>                            
                                <Form.Group controlId="formBasicEmail">
                                    <Form.Label>Adresse email</Form.Label>
                                    <Form.Control
                                        type="email"
                                        onChange={(event) => setEmail(event.target.value)}
                                        value={email}
                                        disabled={lock}
                                        autoComplete="email"
                                    />
                                </Form.Group>
                                <Form.Group controlId="formBasicPassword" className="mt-3">
                                    <Form.Label>Mot de passe</Form.Label>
                                    <InputGroup>
                                        <Form.Control
                                            type={showPass ? "text" : "password"}
                                            onChange={(event) => setPassword(event.target.value)}
                                            value={password}
                                            disabled={lock}
                                            autoComplete="current-password"
                                        />
                                        <InputGroup.Text variant="primary">
                                            {
                                                showPass
                                                ?
                                                <FontAwesomeIcon onClick={() => setShowPass(!showPass)} icon={faEyeSlash} />
                                                :
                                                <FontAwesomeIcon onClick={() => setShowPass(!showPass)} icon={faEye} />
                                            }
                                        </InputGroup.Text>
                                    </InputGroup>
                                </Form.Group>
                            </>
                        }
                        { lock &&
                            <>
                                <hr/>
                                <div className="d-flex flex-column">
                                    <div>
                                        <span className={`${styles.cardHeader}`}>Recevoir mon code d'authentification :</span>
                                    </div>
                                    <div className="d-flex justify-content-center mt-3 gap-1">
                                        <div className={`d-flex justify-content-center ${styles.customtooltip}`} hidden={!availableLoginInMode.includes("mail")} >
                                            <Button variant="primary" className={`m-1 p-2 w-100 ${styles.sendButton} ${loginInMode === 'mail' ? 'active disabled' : ''}`} type="button" onClick={() => setLoginInMode("mail")}>
                                                MAIL
                                            </Button>
                                            <span className={`${styles.tooltiptext} ${loginInMode === 'mail' ? styles.visible: ''}`}>Un code vous a déjà été envoyé par mail</span>
                                        </div>
                                        <div className={`d-flex justify-content-center ${styles.customtooltip}`} hidden={!availableLoginInMode.includes("sms")}>
                                            <Button variant="primary" className={`m-1 p-2 w-100 ${styles.sendButton} ${loginInMode === 'sms' ? 'active disabled' : ''}`} type="button" onClick={() => setLoginInMode("sms")} id='sign-in-button'>
                                                SMS
                                            </Button>
                                            <span className={`${styles.tooltiptext} ${loginInMode === 'sms' ? styles.visible: ''}`}>Un code vous a déjà été envoyé par sms</span>
                                        </div>
                                    </div>
                                </div>
                            </>
                        }
                        {
                            loginInMode &&
                            <Form.Group controlId="formBasicCode" className="mt-3">
                                <Form.Label>Code de sécurité</Form.Label>
                                <OtpCode isReady={isOtpReady} inputs={securityCode} setInputs={setSecurityCode} setOtpCodeValide={setOtpCodeValide}/>
                                <Form.Text className="text-muted">
                                    Un code d'authentification vous a été envoyé par <b>{loginInMode}</b>{loginInMode === 'sms' && ` au ${safeDisplayPhone()}`},{"\n"}
                                    Merci de le renseigner pour continuer
                                </Form.Text>
                                {
                                    codeError &&
                                    <div>
                                        <hr/>
                                        <Form.Text className="mt-3 fw-bold">
                                            Souhaiter vous un nouveau code ? Cliquez <Button className="pb-1 p-0" variant="link" onClick={() => getNewCode()}>ici</Button>.
                                        </Form.Text>
                                    </div>
                                }
                            </Form.Group>
                        }
                        <Button type="submit" variant="primary" className={`mt-3 ${styles.sendButton}`} disabled={submitLock}>
                            CONNEXION
                        </Button>
                    </Form>
                    {
                        !oidcMode && !lock &&
                        <Card.Link as={Link} to={`/reset${email !== "" ? `/${email}` : ''}`}>Mot de passe oublié</Card.Link>
                    }
                </Card.Body>
            </Card>
        </Container>
    );
}

export default Login;