import { Form } from "react-bootstrap";

export default function OtpCode({isReady, inputs, setInputs, setOtpCodeValide}) {

    const checkNumber = (number) => {
        return /[0-9]/g.test(number);
    }

    const isLetter = (input) => {
        return /[a-zA-Z]/g.test(input);
    }

    const onPaste = ($event) => {
        const data = $event.clipboardData.getData("text");
        const value = data.replace(/ /g, "").split("");
        if (!value.some((number) => !checkNumber(number))) {
            if (value.length === inputs.length) {
                inputs = value;
                setInputs(value);
                setOtpCodeValide(true);
            }
        } else {
            return;
        }
    }

    const onKeyUp = ($event, index) => {
        const event = $event.nativeEvent;
        const input = event.target;
        const value = $event.key;
        if ($event.key === "Backspace" && index > 0) {
            input.previousElementSibling.focus();
        }
        if (checkNumber(value)) {
            inputs[index] = value;
            setInputs(inputs);
            input.value = value;
            if (value.length > 0 && index < inputs.length - 1) {
                input.nextElementSibling.focus();
            }
        } else {
            if(value === "Backspace") {
                inputs[index] = "";
                setInputs(inputs);
                input.value = "";
            } else if(value !== "Meta" && value !== "Control" && value !== "Shift" && value !== "Enter" && !isLetter(value)) {
                inputs[index] = "";
                setInputs(inputs);
                input.value = "";
            }
        }
        setOtpCodeValide(inputs.join('').trim().length === 6);
    }

    return (
        <div className="code-container">
            {
                inputs && inputs.map((input, index) => {
                    return (
                        <Form.Control 
                            className="code-input" 
                            key={index} 
                            disabled={!isReady} 
                            value={input} 
                            onPaste={onPaste} 
                            onChange={(_) => {}} 
                            onKeyUp={($event) => onKeyUp($event, index)} 
                            type="text" 
                            maxLength="1" 
                            inputMode="numeric"
                            autoComplete="one-time-code"
                            />
                    )
                })
            }
        </div>
    );
}