import { Navbar, Alert } from "react-bootstrap";
import { Outlet } from "react-router-dom";
import styles from "../../styles/Layout.module.scss"
//import "../../styles/footer.css"
import useMediaQuery from '@mui/material/useMediaQuery';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { GeneralContext } from "../../contexts/GeneralContext";
import { faFacebookF, faInstagram, faLinkedinIn } from "@fortawesome/free-brands-svg-icons"
import { useContext, useRef } from "react";
import { useState } from "react";
import { useEffect } from "react";

export default function Layout({ children }) {
    const baseUrl = "";
    const isMobile = useMediaQuery('(max-width: 900px)');
    const generalDetails = useContext(GeneralContext);
    const [dev, setDev] = useState(false);
    const maintenanceBlock = useRef() //Recupérer l'élément parent si en maintenance

    useEffect(() => {
        if (JSON.parse(localStorage.getItem("dev")) === true && generalDetails.devMode === true) {
            setDev(true);
        }

        if (generalDetails?.maintenance &&
            !dev &&
            maintenanceBlock?.current?.childNodes?.length > 0
        ) {
            const blockChild = maintenanceBlock.current.childNodes[1].className //Récupérer la class de l'enfant si en maintenance

            //console.log(blockChild === styles.maintenanceContainer)
            if (
                blockChild === styles.maintenanceContainer &&
                maintenanceBlock?.current?.childNodes?.length > 2
            ) {
                maintenanceBlock.current.style.backgroundImage = 'none'
                maintenanceBlock.current.childNodes[0].style.display = 'none'
                maintenanceBlock.current.childNodes[2].style.display = 'none'
            }
        }
    }, [dev, generalDetails]);

    return (
        <>
            {
                generalDetails.showSpinner &&
                <div className={`${styles.spinnerContainer} ${process.env.REACT_APP_ENVIRONNEMENT !== "PROD" ? styles.debug : ''}`}>
                    <div className={styles.spinnerPulse}>
                        <img src="/images/1_Illustration_Pastille_Aneo.svg" alt="Illustration_Pastille_Aneo" />
                    </div>
                </div>
            }
            <div className={`${styles.alertContainer}`}>
                <Alert show={generalDetails.alert.show} transition={true} variant={generalDetails.alert.variant} onClose={() => generalDetails.setAlerteShow(false)} dismissible={generalDetails.alert.dismissible}>
                    <Alert.Heading>{generalDetails.alert.header}</Alert.Heading>
                    <p>{generalDetails.alert.message}</p>
                </Alert>
            </div>
            {
                <main ref={maintenanceBlock} className={`${styles.main} ${generalDetails.showSpinner && process.env.REACT_APP_ENVIRONNEMENT !== "PROD" ? styles.containerSpinnerDebug : ''}`} >
                    {generalDetails.showMenu &&
                        <header>
                            <Navbar expand="lg" className={styles.nav}>
                                <Navbar.Brand href={`https://nuvee.fr`} className={styles.logo}>
                                    <img
                                        src="/images/Nuvee.png"
                                        alt="Logo"
                                    />
                                </Navbar.Brand>
                            </Navbar>
                        </header>
                    }

                    {
                        generalDetails.maintenance && !dev &&

                        <div className={styles.maintenanceContainer}>

                            <div className={styles.titleMaintenance}>
                                <a href='https://nuvee.fr'>
                                    {isMobile ?
                                        <img src={`${baseUrl}/images/LOGO_NUVEE_FOOTER.png`} alt='maintenance' /> :
                                        <img src={`${baseUrl}/images/Nuvee.png`} alt='maintenance' />
                                    }
                                </a>
                                <h1><span>Nuvee</span> revient dans quelques minutes, le temps d&apos;une <span>mise à jour</span> !</h1>

                                <div className={styles.networkMaintenance}>
                                    <h2>Pour vous faire patienter un peu, <span>suivez-nous sur les réseaux sociaux !</span></h2>
                                    <div><a href='https://fr.linkedin.com/company/nuvee-epsd' target='_blank' rel="linkedin noreferrer"><FontAwesomeIcon icon={faLinkedinIn} /></a></div>
                                    <div><a href='https://www.instagram.com/_nuvee/' target='_blank' rel="instagram noreferrer"><FontAwesomeIcon icon={faInstagram} /></a></div>
                                    <div><a href='https://www.facebook.com/EMNo_line-410053662921922' target='_blank' rel="facebook noreferrer"><FontAwesomeIcon icon={faFacebookF} /></a></div>
                                </div>
                            </div>
                            <div className={styles.imgMaintenance}>
                                {isMobile ?
                                    <img src={`${baseUrl}/images/maintenance-mobile.png`} alt='Logo maintenance' /> :
                                    <img src={`${baseUrl}/images/maintenance.svg`} alt='Logo maintenance' />}
                            </div>

                        </div>

                    }
                    {
                        (!generalDetails.maintenance || dev) &&
                        <Outlet />
                    }

                    {generalDetails.showFooter &&
                        <footer className={styles.footerDiv}>
                            <div className={styles.footerContainer}>
                                <div className={styles.leftContainer}>
                                    <a href="https://nuvee.fr" ><img src={`${baseUrl}/images/LOGO_NUVEE_FOOTER.png`} className={styles.logoImage} alt="Logo" /></a>
                                </div>
                                <div className={styles.middleContainer}>
                                    <div className={styles.socials}>
                                        <ul>
                                            <li>
                                                <a target="_blank" rel="facebook noreferrer" href="https://www.facebook.com/EMNo_line-410053662921922"><img src={`${baseUrl}/images/facebook.png`} alt="Facebook" /></a>
                                            </li>
                                            <li>
                                                <a target="_blank" rel="twitter noreferrer" href="https://www.instagram.com/_nuvee/"><img src={`${baseUrl}/images/instagram.png`} alt="Instagram" /></a>
                                            </li>
                                            <li>
                                                <a target="_blank" rel="twitter noreferrer" href="https://fr.linkedin.com/company/nuvee-epsd"><img src={`${baseUrl}/images/linkedin.png`} alt="aedin" /></a>
                                            </li>
                                        </ul>
                                    </div>
                                    {isMobile ?
                                        <>
                                            <a href="https://nuvee.fr/mentions-legales">Mentions légales</a>
                                            <a href="https://nuvee.fr/contactez-nous">Contactez-nous</a>
                                            <p>Tél. <b>03 80 58 51 54</b></p>
                                        </>
                                        :
                                        <>
                                            <div className={styles.contact}>
                                                <a href="https://nuvee.fr/mentions-legales">Mentions légales</a>
                                                <a href="https://nuvee.fr/contactez-nous">Contactez-nous</a>
                                            </div>
                                            <div className={styles.legal}>
                                                <p>© Tous droits réservés - EPSD {new Date().year}</p>
                                            </div>
                                        </>
                                    }

                                </div>
                                <div className={styles.rightContainer}>
                                    {isMobile ? <p>© Tous droits réservés - EPSD {new Date().year}</p> :
                                        <>
                                            <p>Tél. 03 80 58 51 54</p>
                                            <p>38 avenue Fran&ccedil;oise Giroud</p>
                                            <p>21000 DIJON</p>
                                        </>}
                                </div>
                            </div>
                        </footer>
                    }
                </main>
            }
        </>
    )
}