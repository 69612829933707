import { useNavigate, useParams } from "react-router-dom";
import {
  Container,
  Card,
} from "react-bootstrap";
import styles from "../../styles/Firstconnexion.module.scss";
import SetupPassword from "../setuppassword";

const ChangePassword = () => {
  let { id, email, token } = useParams();
  const navigate = useNavigate();

  const handlePasswordChange = (password) => {
    navigate('/login', { state: { email: email, password: password, submit: true } });
  }

  return (
    <Container>
      <Card bsPrefix=" " className={`mt-5 ${styles.card}`}>
        <Card.Header className={`${styles.cardHeader}`} as="h1">
          Changement de mot de passe
        </Card.Header>
        <Card.Body className={`mt-4 ${styles.cardBody}`}>
          <SetupPassword id={id} email={email} token={token} afterPasswordChanged={handlePasswordChange} resetPassword={true} />
        </Card.Body>
      </Card>
    </Container>
  );
};

export default ChangePassword;
