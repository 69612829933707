import React from 'react'
import Modal from 'react-bootstrap/Modal';
import styles from '../../styles/Modal.module.scss';

export default function ModalPortal(props) {

    const { handleClose, title, children, footerModal } = props;

    return (
        <Modal
            {...props}
            size="lg"
            aria-labelledby="contained-modal-title-vcenter"
            centered
            onHide={handleClose}
            className={styles.modalContainer}
        >
            <div className={styles.avatarIllustration}>
                <img src="/images/aneoLowDef.png" alt="Illustration fenetre popup" />
            </div>
            <h1 className={styles.modalTitle}>{title}</h1>
            <Modal.Body>
                {children}
            </Modal.Body>
            <div className={styles.modalFooter}>
                {footerModal}
            </div>
        </Modal>
    );
}