import styles from '../../styles/Registration.module.scss';
import React, { useState } from "react";
import { GeneralContext } from '../../contexts/GeneralContext';
import { useContext, useEffect } from "react";
import { useNavigate, useParams } from 'react-router-dom';
import { globalRegex } from '../../utils/utils';
import useMediaQuery from '@mui/material/useMediaQuery';
import axios from 'axios';

const Registration = () => {
    const isMobile = useMediaQuery( '(max-width: 760px)');
    const { thematic : thematic_param } = useParams();

    const [thematic, setThematic] = useState(undefined);
    const [ lastnameOk, setLastnameOk ] = useState( true );
    const [ firstnameOk, setFirstnameOk ] = useState( true );
    const [ dateofbirthOk, setDateofbirthOk ] = useState( true );
    const [ emailOk, setEmailOk ] = useState( true );
    const [ emailTaken, setEmailTaken ] = useState( false );
    const [ zipcodeOk, setZipcodeOk ] = useState( true );
    const [ maternityCodeOk, setMaternityCodeOk ] = useState( true );
    const [ thematicOk, setThematicOk] = useState( true );
    const [error, setError] = useState( '' );
    const [, setEmail] = useState("");
    const [ proTypes, setProTypes ] = useState( [] );
    const [ proSpecialities, setProSpecialities ] = useState( [] );
    const [ exerciseModes, setExerciseModes ] = useState( [] );
    const [ professionOk, setProfessionOk] = useState( true );
    const [ specialityOk, setSpecialityOk ] = useState( true );
    const [ exerciseModeOk, setExerciseModeOk ] = useState( true );
    
    const generalDetails = useContext(GeneralContext);
    const navigate = useNavigate();

    const onEmailChange = (event) => setEmail(event.target.value);

    useEffect(() => {
        axios.get(process.env.REACT_APP_NUVEE_BASE_URL + `/users-permissions/publicAccountRegister/${thematic_param}`)
            .then(response => {
                setExerciseModes(response.data.exerciseModes);
                setProTypes(response.data.protypes);
                setThematic(response.data.thematic);
                generalDetails.setBackgroundImage(response.data.thematic.background)
            })
            .catch((error) => {
                console.error(error);
                generalDetails.setAlert({
                    show: true,
                    message: "Une erreur est survenu.",
                    header: "Erreur interne",
                    dismissible: true,
                    variant: "danger",
                    timout: 5000,
                });
            });
    // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [thematic_param]);

    const handleMaternityChange = ( event ) => {
        let value = event.currentTarget.value;
        if( value.length > 5 ) {
            event.currentTarget.value = value.substring( 0, 5 );
        }
    }

    const handleSubmit = async () => {
        let data= {};
        let completetest = true;
        let maternityCode = '';
        setError( '' );
        let inputs = Array.from( document.getElementsByTagName( 'input' ) );
        let selects = Array.from( document.getElementsByTagName( 'select' ) );
        if( inputs && inputs.length > 0 ) {
            generalDetails.setShowSpinner(true);
            //Check email
            var emailInput = document.getElementsByName( 'email' )[0];
            let email = emailInput.value;
            let emailCheck = email.toLowerCase().match( globalRegex.emailRegex );
            if( email.length !== 0 && emailCheck !== null ) {
                if( emailCheck.includes( email.toLowerCase() ) ) {
                    setEmailOk( true );
                    setEmailTaken( false );
                    data.email = email;
                }
            }
            else {
                completetest = false;
                setEmailOk( false );
                setEmailTaken( false );
                setError("Veuillez renseigner un email valide");
                generalDetails.setShowSpinner(false);
                return;
            }
            if( completetest ) {
                inputs.forEach(element => {
                    let value = element.value;
                    switch (element.name) {                        
                        case 'lastname':
                            let a = value.match( globalRegex.stringRegex );
                            if( a !== null ) {
                                if( a.length === value.length ) {
                                    setLastnameOk( true );
                                    data.lastname = value;
                                    break;
                                }
                            }
                            completetest = false;
                            setLastnameOk( false );
                        break;
                    
                        case 'firstname':
                            let c = value.match( globalRegex.stringRegex );
                            if( c !== null ) {
                                if( c.length === value.length ) {
                                    setFirstnameOk( true );
                                    data.firstname = value;
                                    break;
                                }
                            }
                            completetest = false;
                            setFirstnameOk( false );
                        break;
                        case 'zipcode':
                                let e = value.match( globalRegex.zipcodeRegex );
                                if( e !== null ) {
                                    if( e.length === 1 ) {
                                        setZipcodeOk( true );
                                        data.zipcode = value;
                                        break;
                                    }
                                }
                                completetest = false;
                                setZipcodeOk( false );
                        break;
                        
                        case 'dateofbirth':
                            if( value === '' ) {
                                completetest = false;
                                setDateofbirthOk( false );
                                break;
                            }
                            else {
                                let date = new Date( value );
                                //Add 18 years for majority
                                date.setFullYear( date.getFullYear() + 18 );
                                if( date.getTime() < Date.now() ) {
                                    setDateofbirthOk( true );
                                    data.dateofbirth = new Date( value );
                                    break;
                                }
                            }
                            completetest = false;
                            setDateofbirthOk( false );
                        break;

                        default:
                            break;
                    }
                    
                });
                selects.forEach(element => {
                    let value = element.value;
                    switch ( element.name ) {
                        case 'profession':
                            let p = parseInt(value);
                            if( p !== null ) {
                                if( p > 0 ) {
                                    setProfessionOk(true);
                                    data.type = value;
                                    break;
                                }
                            }
                            completetest = false;
                            setProfessionOk(false);
                            break;
                        case 'speciality':
                            let s = parseInt(value);
                            if( s !== null ) {
                                if( s > 0 ) {
                                    setSpecialityOk(true);
                                    data.speciality = value;
                                    break;
                                }
                            }
                            completetest = false;
                            setSpecialityOk(false);
                            break;
                        case 'exerciseMode':
                            let e = parseInt(value);
                            if( e !== null ) {
                                if( e > 0 ) {
                                    setExerciseModeOk(true);
                                    data.exercise = value;
                                    break;
                                }
                            }
                            completetest = false;
                            setExerciseModeOk(false);
                            break;
                        default:
                            break;
                    }
                });
                if (!completetest) {
                    setError("Une erreur est survenue, certains champs ne sont pas valides");
                }
            }
            else {
                generalDetails.setShowSpinner(false);
                return;
            }

            setThematicOk( false );
            
            //Handle special case (maternity)
            if( thematic && completetest ) {
                if (thematic?.name?.toLowerCase() === 'maternite') {
                    let maternityCodeInput = document.getElementsByName("maternityCode")[0];
                    maternityCode = maternityCodeInput.value;
                    if (maternityCode.length > 5) {
                        setMaternityCodeOk(false);
                        completetest = false;
                        setError("Le code box ne peut faire plus de 5 caractères");
                    }
                    data.thematic = thematic.id;
                    setThematicOk(true);
                } else {
                    data.thematic = thematic.id;
                    setThematicOk(true);
                }
            }
            else {
                generalDetails.setShowSpinner(false);
                return;
            }

            //If every data is OK => 
            if( completetest && thematicOk ) {
                let userData = {
                    firstname: data.firstname,
                    lastname: data.lastname,
                    email: data.email,
                    confirmed: false,
                    thematics: data.thematic,
                    isNuvee: false,
                    Contact: {
                        postalCode: data.zipcode
                    }
                };
                if(thematic?.form_thematic?.phenotypage) {
                    //Envoi des données
                    userData.Additional_Information = [
                        {
                            __component: "additionalinformation.professional",
                            speciality: { id: data.speciality },
                            professional_type: { id: data.type },
                            exercise_mode: { id: data.exercise }
                        }
                    ];
                    let res = await axios.post(process.env.REACT_APP_NUVEE_BASE_URL + '/users-permissions/registerPhenotypage', {
                        data: userData,
                    }).catch((error) => {
                        generalDetails.setAlert({
                            show: true,
                            message: "Une erreur est survenu.",
                            header: "Erreur interne",
                            dismissible: true,
                            variant: "danger",
                            timout: 5000,
                        });
                        generalDetails.setShowSpinner(false);
                        console.error(error);
                    });
                    if(!res) {
                        return;
                    }
                    if(!res.emailOk){
                        setEmailOk(false);
                    }
                } else {
                    //Envoi des données
                    userData.Additional_Information = [
                        {
                            __component: "additionalinformation.patient",
                            dateOfBirth: data.dateofbirth,
                        }
                    ];
                    let res = await axios.post(process.env.REACT_APP_NUVEE_BASE_URL + '/users-permissions/register', {
                        data: userData,
                        maternityCode
                    }).catch((error) => {
                        generalDetails.setAlert({
                            show: true,
                            message: "Une erreur est survenu.",
                            header: "Erreur interne",
                            dismissible: true,
                            variant: "danger",
                            timout: 5000,
                        });
                        generalDetails.setShowSpinner(false);
                        console.error(error);
                    });
                    if(!res) {
                        return;
                    }
                    if(!res.emailOk) {
                        setEmailOk(false);
                    }
                    else if(!res.maternityCodeOk) {
                        setMaternityCodeOk(false);
                    }
                }
                navigate('/login');
            }
            else if( error.length === 0 ) {
                setError( "Une erreur est survenue" );
            }

            generalDetails.setShowSpinner(false);
        }        
    }

    const handleProtypeChange = (proTypeId) => {
        if(proTypeId) {
            let data = proTypes.find((pt) => pt.id === Number(proTypeId));
            if( data ) {
                setProSpecialities(data.specialities);
            } else {
                setProSpecialities( [] );
            }
        }
    }

    return (
        <>
            {
                thematic && (
                    <div className={`${styles.container} ${styles[thematic?.theme]}`}>
                        <div className={styles.container} style={{margin:"0 5vw"}}>
                            <main className={styles.main}>
                                <h1 className={styles.title}>
                                    {thematic?.form_thematic?.title || `Créer mon compte ${thematic?.title}`}
                                </h1>
                                {thematic?.form_thematic?.description && <p className={styles.description}>{thematic.form_thematic.description}</p>}
                                <div className={styles.containerFormDiabete}>
                                    {
                                        thematic?.name.indexOf("diabete") > -1
                                        ? <img src="/images/Diabeteformulaire.png" alt="" />
                                        : (
                                            <>
                                                {thematic?.form_thematic?.phenotypage && thematic?.name?.indexOf("recommandations_has") > -1 ?
                                                    <img src="/images/Phenotypageformulaire.png" alt="" />
                                                    :
                                                    <img src="/images/Diabeteformulaire.png" alt="" />
                                                }
                                            </>
                                        )
                                    }

                                    <div className={styles.subContainerFormDiabete}>
                                        <div className={styles.patientForm}>
                                            <span className={styles.allFieldRequired}>
                                                    * Tous les champs sont obligatoires
                                            </span>
                                            <h4>
                                                VOS COORDONNÉES
                                                <div style={thematic?.color && thematic?.name.indexOf("diabete") === -1 ? { borderBottomColor: thematic.color } : {}}></div>
                                            </h4>
                                            <div className={styles.patientForm}>
                                                <div className={styles.formContainer}>
                                                    <div className={styles.boxFlex}>
                                                        <label>
                                                            Nom
                                                        </label>
                                                        <input type="text" className={lastnameOk ? styles.inputok : styles.inputko} name="lastname" />
                                                    </div>
                                                    <div className={styles.boxFlex} style={{marginLeft: `${!isMobile ? "1em" : '0'}`}}>
                                                        <label>
                                                            Pr&eacute;nom
                                                        </label>
                                                        <input type="text" className={firstnameOk ? styles.inputok : styles.inputko} name="firstname" />
                                                    </div>
                                                </div>
                                                {thematic?.form_thematic?.phenotypage &&
                                                    <>
                                                        {proTypes.length > 0 &&
                                                            <label className={styles.selectLabel}>
                                                                Profession
                                                                <select className={professionOk ? styles.inputok : styles.inputko}
                                                                        name="profession"
                                                                        onChange={(evt) => handleProtypeChange(evt.target.value)}
                                                                >
                                                                    <option value={0}>Sélectionnez une profession</option>
                                                                    {proTypes.map(pro => {
                                                                        return <option value={pro.id}>{pro.name}</option>
                                                                    })}
                                                                </select>
                                                            </label>
                                                        }
                                                        {proSpecialities.length > 0 &&
                                                            <label className={styles.selectLabel}>
                                                                Sp&eacute;cialit&eacute;
                                                                <select className={specialityOk ? styles.inputok : styles.inputko}
                                                                    name="speciality">
                                                                    <option value={0}>Sélectionnez une sp&eacute;cialit&eacute;</option>
                                                                    {proSpecialities.map(spe => {
                                                                        return <option value={spe.id}>{spe.name}</option>
                                                                    })}
                                                                </select>
                                                            </label>
                                                        }
                                                        {exerciseModes.length > 0 &&
                                                            <label className={styles.selectLabel}>
                                                                Mode d&apos;exercice
                                                                <select className={exerciseModeOk ? styles.inputok : styles.inputko}
                                                                    name="exerciseMode"
                                                                >
                                                                    <option value={0}>Sélectionnez un mode d'exercice</option>
                                                                    {exerciseModes.map(mode => {
                                                                        return <option value={mode.id}>{mode.name}</option>
                                                                    })}
                                                                </select>
                                                            </label>
                                                        }
                                                    </>
                                                }
                                                <div className={styles.formContainer}>
                                                    <div className={styles.boxFlex}>
                                                        <label>
                                                            Code postal
                                                        </label>
                                                        <input type="text" className={zipcodeOk ? styles.inputok : styles.inputko} name="zipcode" />
                                                    </div>
                                                        {!thematic?.form_thematic?.phenotypage &&
                                                            <div className={styles.boxFlex} style={{marginLeft: `${!isMobile ? "1em" : '0'}`}}>
                                                                <label>
                                                                    Date de naissance
                                                                </label>
                                                                <input type="date" placeholder="dd-mm-yyyy" className={dateofbirthOk ? styles.inputok : styles.inputko} name="dateofbirth" />
                                                            </div>
                                                        }
                                                </div>
                                                <div className={styles.email}>
                                                    <label>
                                                        <span>Adresse mail</span>
                                                        <input type="text" className={emailOk ? styles.inputok : styles.inputko} name="email" onChange={onEmailChange} />
                                                    </label>
                                                    <div className={emailTaken ? styles.emailtaken : styles.emailnottaken}>
                                                        Un compte Nuvee utilise déjà cette adresse mail
                                                    </div>
                                                </div>
                                                {( typeof( thematic?.name ) == 'string' && thematic?.name.toLowerCase() === 'maternite' ) &&
                                                    <label>
                                                        CODE DE LA BOX
                                                        <input type="text" className={maternityCodeOk ? styles.inputok : styles.inputko} name="maternityCode" onChange={handleMaternityChange} />
                                                    </label>
                                                }
                                            </div>

                                            <div className={styles.send}>
                                                <button style={thematic?.color && thematic?.name.indexOf("diabete") === -1 ? { background: thematic.color } : {}} onClick={handleSubmit}>
                                                    CRÉER MON COMPTE
                                                </button>
                                                {error.length === 0 ?
                                                    null
                                                    :
                                                    <div className={styles.sendError}>
                                                        {error}
                                                    </div>
                                                }
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </main>
                        </div>
                    </div>
                )
            }
        </>
    )
}

export default Registration;